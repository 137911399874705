import React, { useContext, useEffect, useState } from "react"
import "./consumerComplainPart.scss"
import AppDataContext from "../../contexts/AppDataContext"
import { t } from "i18next"

const datas = [
  {
    value: "-",
    title: "contact.consumer-complain-statistic.collection",
    key: "collection",
  },
  {
    value: "-",
    title: "contact.consumer-complain-statistic.payment-information",
    key: "paymentInformation",
  },
  {
    value: "-",
    title: "contact.consumer-complain-statistic.aplication-information",
    key: "applicationInformation",
  },
  {
    value: "-",
    title: "contact.consumer-complain-statistic.loan-cancelation",
    key: "loanCancellation",
  },
  {
    value: "-",
    title: "contact.consumer-complain-statistic.discount",
    key: "discount",
  },
  {
    value: "-",
    title: "contact.consumer-complain-statistic.tenor-information",
    key: "tenorInformation",
  },
  {
    value: "-",
    title: "contact.consumer-complain-statistic.fine",
    key: "fine",
  },
  {
    value: "-",
    title: "contact.consumer-complain-statistic.bill-information",
    key: "billInformation",
  },
  {
    value: "-",
    title: "contact.consumer-complain-statistic.request-otp-code",
    key: "requestOtpCode",
  },
  {
    value: "-",
    title: "contact.consumer-complain-statistic.disburse-problem",
    key: "disbursementProblem",
  },
]

const ConsumerComplainPart = () => {
  const [complainData, setComplainData] = useState(datas)
  const [datePeriod, setDatePeriod] = useState("")
  const appContext = useContext(AppDataContext)

  function castingAPIData() {
    const tempComplainData = Object.assign([], complainData)
    const isObjectEmpty =
      Object.keys(appContext.consumerComplainData).length == 0

    if (!isObjectEmpty) {
      setDatePeriod(appContext.consumerComplainData["dataPeriod"])
      for (const [key, value] of Object.entries(
        appContext.consumerComplainData
      )) {
        const dataIndex = tempComplainData.findIndex(e => e.key == key)
        if (dataIndex != -1) {
          tempComplainData[dataIndex].value = value
        }
      }
    }
    setComplainData(tempComplainData)
  }

  useEffect(() => {
    castingAPIData()
  }, [appContext.consumerComplainData])

  return (
    <div className="container-block">
      <div className="square-bg" />
      <div className="content-block">
        <div className="title-month-block">
          <div className="line" />
          <div className="title">
            {t("contact.consumer-complain-statistic.title")}
            <div className="month">{datePeriod}</div>
          </div>
        </div>
        <div className="complain-block">
          {complainData.map((e, i) => (
            <div className="complain-data" key={i}>
              <div className="value">{e.value}</div>
              <div className="title">{t(e.title)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ConsumerComplainPart
